<template>
  <el-form class="form" :rules="rules" ref="registerFrom" :model="register">
    <el-form-item label="Prénom et Nom" prop="full_name">
      <el-input class="w-full" type="text" v-model="register.full_name" />
    </el-form-item>
    <el-form-item label="Email" prop="email">
      <el-input class="w-full" type="email" v-model="register.email" />
    </el-form-item>
    <el-form-item label="Telephone" prop="phone">
      <el-input class="w-full" type="text" v-model="register.phone" />
    </el-form-item>
    <el-form-item label="Mot de passe" prop="password">
      <el-input type="password" v-model="register.password" show-password />
    </el-form-item>
    <el-form-item label="Confirmé Mot de passe" prop="confirmPassword">
      <el-input
        type="password"
        v-model="register.confirmPassword"
        show-password
      />
    </el-form-item>

    <div class="flex flex-row">
      <el-checkbox name="type">
        <p class="text-xs font-normal text-gray-400">
          Je reconnais avoir lu et accepté les
          <a href="#" class="text-blue-300">Condition generale d'utilsation</a>
          <br />
          ainsi que la
          <a href="#" class="text-blue-300">politique de confidentialité</a>
        </p>
      </el-checkbox>
    </div>
    <div class="flex flex-row justify-between items-center mb-3">
      <el-button
        class="w-full mt-5"
        type="primary"
        :loading="loading"
        :disabled="loading"
        @click="handlRegister('registerFrom')"
      >
        Inscription
      </el-button>
    </div>
  </el-form>
</template>

<script>
import { registerUser } from "@/api/user";
export default {
  name: "Register",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Veuillez entrer le mot de passe"));
      } else {
        if (this.register.confirmPassword !== "") {
          this.$refs.registerFrom.validateField("confirmPassword");
        }
        callback();
      }
    };

    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Veuillez entrer à nouveau le mot de passe"));
      } else if (value !== this.register.password) {
        callback(new Error("Les deux entrées ne correspondent pas!"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      hasText: false,
      text: null,
      register: {
        full_name: "",
        phone: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      rules: {
        full_name: [
          {
            required: true,
            message: "Le nom est obligatoire",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "Le téléphone est obligatoire",
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: "Email est obligatoire",
            trigger: "change",
          },
        ],
        password: [{ validator: validatePass, trigger: "blur" }],
        confirmPassword: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
    handlRegister(registerFrom) {
      this.$refs[registerFrom].validate((valid) => {
        if (valid) {
          this.loading = true;
          registerUser(this.register)
            .then(() => {
              setTimeout(() => {
                this.loading = false;
                this.$$router.push({
                  path: "/signin",
                });
              }, 3000);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          console.log("hello");
        }
      });
    },
  },
};
</script>

<style></style>
