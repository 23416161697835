<template>
  <div class="font-Inter flex flex-row h-screen">
    <div class="w-1/2 flex flex-col justify-start">
      <div class="flex flex-row">
        <router-link class="p-4 font-light flex flex-row" to="/signin">
          Vous avez un compte ?

          <span class="text-purple-400 hover:text-purple-500 ml-1"
            >contectez-vous</span
          ></router-link
        >
      </div>
      <div class="w-full px-20">
        <div class="my-12 font-normal text-3xl">
          Créer votre compte pour commencer l'aventure avec
          <span class="text-yellow-400 font-medium"> FIIFOP Solution </span>
        </div>

        <Form v-if="step === 'FORM'" />
        <Success v-if="step === 'SUCCESS'" />
      </div>
      <div class="flex"></div>
    </div>
    <div
      class="w-1/2 text-white flex flex-col justify-center items-center"
    ></div>
  </div>
</template>

<script>
//import Logo from "@/components/Logo";
//import Notification from "@/components/Notification";

import Form from "./form.vue";
import Success from "./success.vue";
export default {
  name: "Register",
  components: { Form, Success },
  data() {
    return {
      step: "FORM",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
