<template>
  <div
    class="h-full flex-1 justify-center items-center text-center flex flex-col"
  >
    <h1 class="text-green-400 text-2xl">
      Félicitation votre inscription a réussie !
    </h1>
    <p class="font-light mt-4">
      Veuillez consulter votre boite email pour valider l'inscription et acceder
      aux services
    </p>
    <router-link class="w-1/2 mt-5" to="/signin">
      <el-button
        type="primary"
        :loading="loading"
        :disabled="loading"
        @click="handlRegister('registerFrom')"
      >
        Terminer
      </el-button>
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
